import { useConfig } from '@/hooks/use-config';
import { homeSections } from '@/registry/home-sections';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

interface SectionContextType {
    section: number;
    setSection: (state: number) => void;
    showFooter: boolean;
    setShowFooter: (state: boolean) => void;
    moveSection: (state: number, s?: number) => void;
    scrollTo: (state: number, straighTo?: number) => void;
}

const defaultValues = {
    section: 0,
    setSection: () => {},
    showFooter: false,
    setShowFooter: () => {},
    moveSection: () => {},
    scrollTo: () => {}
}

export const opacityVariants = (opacity: number) => {
    return {
        view: {
            opacity: opacity
        },
        hide: {
            opacity: 0
        }
    }
}

const SectionContext = createContext<SectionContextType>(defaultValues);

export const useSections = () => {
    return useContext(SectionContext);
}

export const SectionProvider = ({ children }: { children: React.ReactNode }) => {

    const [config, setConfig] = useConfig();

    const [section, setSection] = useState<number>(defaultValues.section);
    const [showFooter, setShowFooter] = useState<boolean>(defaultValues.showFooter);

    const isAnimatingRef = useRef(false);
    const lastScrollTime = useRef(0);
    const scrollCooldown = 1200; // milliseconds
    const wheelEventGap = 800; // milliseconds

    const moveSection = useCallback((delta: number, straightTo?: number) => {
        if (isAnimatingRef.current) return;

        if (showFooter && delta < 0) {
            setShowFooter(false);
            setTimeout(() => {
                isAnimatingRef.current = false;
            }, scrollCooldown);
            return;
        }

        const now = Date.now();
        if (now - lastScrollTime.current < wheelEventGap) return;

        const canBack = section > 0;
        const canForward = section < homeSections.length-1;
        
        if (delta < 0 && !canBack) return;
        if (delta > 0 && !canForward && showFooter) return;

        isAnimatingRef.current = true;
        lastScrollTime.current = now;

        const footerUpdate = (delta > 0 && !canForward);

        setShowFooter(footerUpdate);

        const change = delta > 0 
            ? canForward ? 1 : 0 
            : canBack ? -1 : 0;

        const moveTo = straightTo !== undefined ? straightTo : section + change;
        setConfig({
            ...config,
            theme: homeSections[moveTo]?.theme || "dark-purple"
        });

        setSection(moveTo);

        setTimeout(() => {
            isAnimatingRef.current = false;
        }, scrollCooldown);

    }, [section, showFooter, config])

    const scrollTo = useCallback((adj: number, straighTo?: number) => {
        if (isAnimatingRef.current) return;

        if (showFooter && adj < 0) {
            setShowFooter(false);
            setTimeout(() => {
                isAnimatingRef.current = false;
            }, scrollCooldown);
            return;
        }

        const now = Date.now();
        if (now - lastScrollTime.current < wheelEventGap) return;

        const canBack = section > 0;
        const canForward = section < homeSections.length-1;
        
        if (adj < 0 && !canBack) return;
        if (adj > 0 && !canForward && showFooter) return;

        if (section < (homeSections.length -1) || adj === -1 || straighTo !== undefined) {
            const goto = straighTo !== undefined ? straighTo : section + adj;
            document.getElementById(homeSections[goto].id)?.scrollIntoView();
        } else if ((homeSections.length -1) === section && adj === 1) {
            setShowFooter(true);
        }
    }, [section, showFooter]);

    useEffect(() => {
        setSection(0);
        setConfig({
            ...config,
            theme: homeSections[0].theme
        });
    }, [])

    return (
        <SectionContext.Provider value={{
            section,
            setSection,
            showFooter,
            setShowFooter,
            moveSection,
            scrollTo
        }}>
            {children}
        </SectionContext.Provider>
    );
};