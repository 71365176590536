import { SectionText, SectionTitle } from '@/components/ui/text';
import nebulaImg from '@/assets/images/nebula.webp'
import starsImg from '@/assets/images/stars.svg'
import playerImg from '@/assets/images/battle.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps, transition } from '@/components/animations/utils';
import { useSections } from '@/components/section-provider';
import { forwardRef, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import bitbVideo from '@/assets/animations/bitb.webm'
import { VideoAnimation } from '@/components/animations/video';
import { SectionProps } from '@/registry/home-sections';

export const BattleInTheBeyond = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
    <>
        <motion.section id={id} ref={ref} key="bitb" className={cn('flex h-screen w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <motion.img 
                src={starsImg} 
                className='opacity-50 fixed inset-0 h-full md:w-full z-0' 
                transition={{ duration: 0.5 }}
                animate={isInView ? "show" : "hide"}
                variants={{
                    show: {
                        opacity: 0.5
                    },
                    hide: {
                        opacity: 0
                    }
                }}
            />
            <BrowserView>
                <motion.img 
                    src={nebulaImg} 
                    className='opacity-50 absolute inset-0 h-full md:w-full z-0' 
                    initial={{ opacity: 0 }}
                    animate={isInView ? "show" : "hide"}
                    variants={{
                        show: {
                            opacity: 0.5
                        },
                        hide: {
                            opacity: 0
                        }
                    }}
                    transition={transition}
                />
                
                {/* <motion.img 
                    src={playerImg} 
                    className='absolute -right-14 md:-right-28 bottom-0 md:h-[90%] z-0 whitespace-nowrap' 
                    {...slideProps("right", isInView)}
                /> */}
                <VideoAnimation
                    sectionIndex={2}
                    isInView={isInView}
                    videoId='bitb'
                    className='absolute -right-14 md:-right-28 bottom-0 md:h-full z-0' 
                    {...slideProps("right", isInView)}
                />
            </BrowserView>
            <MobileView>
                <div 
                    style={{ backgroundImage: `url(${nebulaImg})`, backgroundSize: 'auto 100%', backgroundPosition: '65%', opacity: 0.55, content: ' ' }} 
                    className='block fixed w-full bg-no-repeat h-full z-0 bg-center opacity-90'
                />
                <div className='fixed inset-0 w-full h-full bg-black opacity-20' />
                <motion.div 
                    style={{ backgroundImage: `url(${playerImg})`, backgroundSize: 'auto 100%', content: ' ' }} 
                    className='block fixed -left-14 -right-32 -bottom-24 bg-no-repeat h-full z-0 max-h-[480px]'
                    {...slideProps("right", isInView)}
                />
            </MobileView>
            
            <div className='relative mx-auto h-4/5 md:h-full max-w-[95%] lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full md:col-span-3 px-2 lg:px-0 gap-4 w-full md:h-auto text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-4 justify-start lg:-mt-8 md:mt-0'>
                        <SectionTitle isInView={isInView}>Battle in the Beyond</SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] md:max-w-lg lg:max-w-xl lg:py-5 lg:text-base'>
                            Challenge opponents to D1SK Battles and reign victorious in our hit PVP collection game! 
                            Illuvium game studio partnered with us to create a new immersive experience where you can wager and climb seasonal leaderboards to earn greater rewards!
                        </SectionText>
                    </div>
                    <motion.a 
                        href="https://battleinthebeyond.io/" 
                        target="_blank"
                        className={cn(buttonVariants(), 'font-bold mt-2 px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Play Now
                    </motion.a>
                </div>
            </div>
        </motion.section>
    </>
    );
});