import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinHero from '@/assets/images/heroes/7.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { useSections } from '@/components/section-provider';
import { forwardRef, useRef } from 'react';
import Stars from '@/components/stars';
import { SectionProps } from '@/registry/home-sections';
import { VideoAnimation } from '@/components/animations/video';
import lizcoinVideo from '@/assets/animations/lizcoin.webm';
import { BrowserView, MobileView } from 'react-device-detect';

export const Lizcoin = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="fllowus" className={cn('flex h-full w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full gap-4 w-full items-center px-2 lg:px-0 text-left md:items-start'>
                    <div className='flex flex-col gap-3 lg:gap-4 justify-center w-full'>
                        <SectionTitle isInView={isInView}>
                            Lizcoin ($LIZ)
                        </SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] md:max-w-2xl lg:py-5 text-base font-figtree !leading-6'> 
                            We designed $LIZ to become the a token that brings together the best in Web3 gaming. 
                            Earn monthly Loot Box Rewards and Staking Season Rewards to fill your Vault with $LIZ and top Web3 gaming tokens.
                        </SectionText>
                    </div>
                    <motion.a 
                        href="/lizcoin" 
                        className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-black font-figtree !font-bold shadow-cta px-6 text-[17px] hover:scale-[1.02] !h-14 !rounded-[6px]')}
                        {...slideProps("up", isInView, true)}
                    >
                        Lizcoin Yo Life Up
                    </motion.a>
                </div>
            </div>
        </motion.section>
        <Stars 
            className='fixed w-full h-full inset-0 opacity-30 z-0 fill-[#2C70F4]' 
            animate={isInView ? { opacity: 0.3 } : { opacity: 0 }}
        />
        <motion.div animate={isInView ? { opacity: 1 } : { opacity: 0 }} className='bg-eclipse fixed left-0 -top-[25%] h-[150%] w-[120%] rounded-full z-0' />
        <BrowserView renderWithFragment>
            <VideoAnimation
                isInView={isInView}
                videoId='lizcoin'
                className='fixed right-[10%] bottom-[15%] lg:h-[70%] z-0' 
                {...slideProps("right",isInView)}
            />
        </BrowserView>
        <MobileView renderWithFragment>
              <motion.img
                  src={lizcoinHero}
                  className='z-0 w-full -mt-20'
                  {...slideProps("right", isInView)}
              />
          </MobileView>
        </>
    );
});