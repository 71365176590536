import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinImage from '@/assets/images/heroes/lizcoin/why-lizcoin.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export const WhyLizcoin = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="why-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 min-h-[110vh] lg:min-h-[70vh]"
        >
            <div ref={ref} className='relative grid grid-cols-6 justify-center items-center w-full z-[1]'>
                <BrowserView renderWithFragment>
                    <motion.img
                        src={lizcoinImage}
                        className='col-span-3'
                        {...slideProps("right", isInView)}
                    />
                </BrowserView>
                <motion.div className='flex flex-col gap-y-[18px] col-span-6 lg:col-span-3' {...slideProps("left", isInView)}>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>Why Lizcoin?</SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Lizards know that Web3 gaming is inevitable. That's why we partner with the top game studios in Web3, building the future of gaming.
                        </SectionText>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Who wouldn't want to own a piece of the ecosystem that's at the Nexus of inevitability? Plus there's this other stuff you can read below.
                        </SectionText>
                    </div>
                    <MobileView renderWithFragment>
                        <motion.img
                            src={lizcoinImage}
                            className='w-full'
                            {...slideProps("right", isInView)}
                        />
                    </MobileView>
                </motion.div>
            </div>
        </section>
    );
};