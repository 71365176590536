
import cloudImg from '@/assets/images/cloud.webp'
import cloudImgMobile from '@/assets/images/cloud-mobile.webp'
import { SectionText, SectionTitle } from '@/components/ui/text';

import heroImg from '@/assets/images/heroes/4.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps, transition } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { forwardRef, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ravenVideo from '@/assets/animations/raven.webm'
import { VideoAnimation } from '@/components/animations/video';
import { SectionProps } from '@/registry/home-sections';

export const ElementalLizardsPortal = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="elp" className={cn('flex h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='col-span-full gap-4 w-full items-center px-2 lg:px-0 text-left md:items-start'>
                    <div className='flex flex-col gap-4 justify-center'>
                        <SectionTitle isInView={isInView}>Elemental Lizards</SectionTitle>
                        <SectionText isInView={isInView} className='md:max-w-xl lg:py-5 lg:text-base'>
                            They aren't just a profile picture, Elemental Lizards are continuously evolving RPG-like characters that take you on an epic journey into the best Web3 games, 
                            where your decisions steer the direction of lizard lore.
                        </SectionText>
                    </div>
                    <motion.button 
                        disabled
                        className={cn(buttonVariants(), 'font-bold lg:mt-2 px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Access Soon
                    </motion.button>
                </div>
                <BrowserView className='relative col-span-full md:col-span-2 flex justify-center w-full'>
                    {/* <motion.img
                        src={heroImg}
                        className='w-1/2 md:w-auto -mt-32 md:-mt-20'
                        animate={isInView ? "show" : "hide"}
                        transition={transition}
                        variants={{
                            show: {
                                opacity: 1, x: 0, scale: 1.25
                            },
                            hide: {
                                opacity: 0, x: 150, scale: 1.25
                            }
                        }}
                    /> */}
                    <VideoAnimation
                        sectionIndex={3}
                        isInView={isInView}
                        videoId='raven'
                        className='w-1/2 md:w-auto'
                        animate={isInView ? "show" : "hide"}
                        transition={transition}
                        variants={{
                            show: {
                                opacity: 1, x: 0, scale: 1.35
                            },
                            hide: {
                                opacity: 0, x: 150, scale: 1.35
                            }
                        }}
                    />
                </BrowserView>
                <MobileView>
                    <motion.img
                        src={heroImg}
                        className='absolute bottom-0 mx-auto z-0 w-full'
                        {...slideProps("right", isInView)}
                    />
                </MobileView>
            </div>
        </motion.section>
        <motion.img 
            src={cloudImg} 
            className='opacity-90 fixed left-0 bottom-0 w-auto z-0 hidden lg:block' 
            {...slideProps("left", isInView)}
        />
        <motion.img 
            src={cloudImgMobile} 
            className='opacity-90 fixed left-0 bottom-0 w-auto z-0 lg:hidden block' 
            {...slideProps("left", isInView)}
        />
        </>
    );
});