import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinImage from '@/assets/images/heroes/lizcoin/what-is-lizcoin.webp';
import lizcoinMobileImage from '@/assets/images/heroes/lizcoin/what-is-lizcoin-mobile.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export const WhatIsLizcoin = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="what-is-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 md:px-20 lg:min-h-[70vh]"
        >
            <div ref={ref} className='relative flex flex-col justify-center items-start gap-y-8 z-[1]'>
                <motion.div className='flex flex-col gap-y-[18px] pt-8 lg:pt-0' {...slideProps("left", isInView)}>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>What is Lizcoin?</SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Forged deep in our reptilian repository, Lizcoin ($LIZ) unlocks the scaly potential of Web3 gaming, enhancing the experience for Web3 gamers everywhere (especially the scaly ones).
                        </SectionText>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Complete Cold-Blooded Bounties to fill your Vault with Lizcoin and exclusive rewards from top web3 gaming studios!
                        </SectionText>
                    </div>
                </motion.div>
            </div>
            <BrowserView renderWithFragment>
                <motion.img
                    src={lizcoinImage}
                    className='absolute -right-48 w-full'
                    {...slideProps("right", isInView)}
                />
            </BrowserView>
            <MobileView renderWithFragment>
                <div className='h-48 -mt-12'>
                    <motion.img
                        src={lizcoinMobileImage}
                        className='absolute left-0 right-0'
                        {...slideProps("right", isInView)}
                    />
                </div>
            </MobileView>
        </section>
    );
};