import { homeSections, PageSection } from "@/registry/home-sections";
import { SectionProvider, useSections } from "@/components/section-provider";
import dotImg from "@/assets/images/dot.png";
import dotBlackImg from "@/assets/images/dot-black.png";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion, useInView } from "framer-motion";
import { ArrowDown, ArrowUp } from "lucide-react";
import { useEffect, useRef } from "react";
import Footer from "@/components/footer";
import { Header } from "@/components/navigation/header";
import ReactGA from 'react-ga4';
import { Lizcoin } from "@/components/sections/home/lizcoin";
import { useConfig } from "@/hooks/use-config";
import { isMacOs } from "react-device-detect";

const HomeLayout = ({ children }: { children: React.ReactElement }) => {
    return <div className="relative home-page flex h-dynamic w-full flex-col items-center overflow-hidden">
        <Header/>
        {children}
    </div>
}

const SectionDots = () => {

    const { section, scrollTo } = useSections();

    const sec = homeSections[section];

    const variants = {
        active: {
            width: 20,
            height: 20
        },
        neighbour: {
            width: 14,
            height: 14
        },
        dot: {
            width: 8,
            height: 8
        }
    }

    return (
        <div className="hidden lg:flex flex-col items-center justify-center fixed left-12 h-full z-10 -mt-10 w-10">
            {homeSections.map((s, x) => {
                const categoryActive = section === x;
                const isNeighbour = section-1 === x || section+1 === x;
                const opacity = categoryActive || isNeighbour ? 1 : Math.abs(1 / (x - section));
                return <div id={`btn_moveto_${sec.id}`} key={`cat_${s.id}`} className="relative rounded-lg transition-all duration-300 py-1 px-2 cursor-pointer" onClick={() => scrollTo(0, x)}> 
                    <motion.img 
                        src={dotImg} 
                        className={cn("transition-all duration-300 color-dot")} 
                        variants={variants}
                        animate={categoryActive ? 'active' : isNeighbour ? 'neighbour' : 'dot'}
                        style={{ opacity }} 
                        
                    />
                    <motion.img 
                        src={dotBlackImg} 
                        className={cn("transition-all duration-300 black-dot")} 
                        variants={variants}
                        animate={categoryActive ? 'active' : isNeighbour ? 'neighbour' : 'dot'}
                        style={{ opacity }} 
                    />
                </div>
            })}
        </div>
    )
}

const SectionArrows = () => {

    const { section, scrollTo } = useSections();

    const backDisabled = section === 0;
    const forwardDisabled = section === homeSections.length-1;
    
    useEffect(() => {
        const keyPress = (event: KeyboardEvent) => {
            event.preventDefault();
            if (event.code === "ArrowDown" || event.code === "ArrowRight") {
                scrollTo(1);
            } else if (event.code === "ArrowUp" || event.code === "ArrowLeft") {
                scrollTo(-1);
            }
        }

        window.addEventListener("keydown", keyPress);

        return () => window.removeEventListener("keydown", keyPress);
    }, [section, scrollTo])
  
    return <div className="flex gap-1 fixed right-6 bottom-6 z-10">
        <Button 
            id="section_back"
            type="button"
            variant="ghost"
            onClick={(e) => {
                e.preventDefault();

                scrollTo(-1);
            }}
            className={cn("relative rounded-full bg-arrows w-14 h-14 lg:w-10 lg:h-10 hover:scale-105 transition-all duration-150", backDisabled && "hidden")}
        >
            <ArrowUp className={cn("absolute h-8 w-8 lg:h-6 lg:w-6 text-white", backDisabled && "hidden")} />
        </Button>
        <Button 
            id="section_next"
            type="button"
            variant="ghost" 
            onClick={(e) => {
                e.preventDefault();

                scrollTo(1);
            }}
            className="relative rounded-full bg-arrows w-14 h-14 lg:w-10 lg:h-10 hover:scale-105 transition-all duration-150"
        >
            <ArrowDown className={cn("absolute h-8 w-8 lg:h-6 lg:w-6 text-white", forwardDisabled && "opacity-70")} />
        </Button>
    </div>
}

const Section = ({ section }: { section: PageSection }) => {

    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, {
        amount: 0.5
    });
    const [config, setConfig] = useConfig();
    const { setSection } = useSections();

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isInView) {
            timeout = setTimeout(() => {
                setConfig({
                    ...config,
                    theme: section.theme
                });
            }, 0);
            
            setSection(homeSections.findIndex(s => s.id === section.id));
        }
        return () => clearTimeout(timeout);
    }, [isInView])

    return <section.component ref={sectionRef} key={`section_${section.id}`} isInView={isInView} id={section.id} />
};

export const SectionContent = () => {

    const { setShowFooter } = useSections();

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        setShowFooter(bottom);
    }

    return <div 
        id="home_sections"
        className="relative w-full h-100vh !overflow-x-hidden overflow-y-scroll"
        onScroll={handleScroll}
    >
        <AnimatePresence>
            {homeSections.map((section) => (
                <Section key={section.id} section={section} />
            ))}
        </AnimatePresence>
    </div>
}

export const HomePage = () => {

    ReactGA.send({
        hitType: 'pageview',
        page: "/",
        title: 'Home'
    });

    useEffect(() => {
        if (!isMacOs) {
            document.getElementById("home_sections")?.classList.add("scrollbar", "scrollbar-thumb-background/0", "scrollbar-track-background/0");
        }  else {
            document.getElementById("home_sections")?.classList.remove("scrollbar", "scrollbar-thumb-background/0", "scrollbar-track-background/0");
        }
    }, [isMacOs])

    return (
        <SectionProvider>
            <HomeLayout>
                <>
                <SectionDots />
                <SectionArrows />
                <main className="relative flex flex-col w-full h-full z-[1] overflow-hidden">
                    <SectionContent />
                </main>
                <Footer />
                </>
            </HomeLayout>
        </SectionProvider>
    );
}