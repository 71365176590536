import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { SectionTitle } from '@/components/ui/text';
import Stars from '@/components/stars';
import LinearStars from '@/components/linear-stars';
import logo from '@/assets/images/white-logo.svg';

export const PitchDeck = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="pitch-deck"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:justify-between md:flex-row gap-y-8 pt-20 px-6 md:px-20 lg:min-h-[100vh]"
        >
            <div ref={ref} className='flex justify-center mx-auto w-full z-[1] h-full'>
                
                <motion.div className='relative flex flex-col gap-y-9 col-span-1 w-full max-w-[883px] justify-center items-center' {...slideProps("up", isInView)}>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>Pitch Deck</SectionTitle>
                    <div className='relative flex flex-col w-full'>
                        <LinearStars className="absolute -bottom-[33.3%] w-full h-[155px] lg:h-[419px]"/>
                        <div className="relative aspect-video bg-[#1f1e40] w-full p-4">
                            <iframe 
                                src="https://docs.google.com/presentation/d/1QwBRR9JmfX6pGaQH3kCLu6r49tFfWh9CNuE6JzBDzUk/view?embedded=true" 
                                className='w-full h-full rounded-xl'
                                width="640" 
                                height="718" 
                                frameBorder="0" 
                                marginHeight={0}
                                marginWidth={0}
                            >Loading…</iframe>
                            
                            <div className='absolute top-0 right-0 bg-[#1f1e40] p-4 w-1/5 rounded-bl-xl inverted-border-radius'>
                                <div className='relative w-full h-full'>
                                    <div className='absolute top-0 left-0 inverted-border-radius-left' />
                                </div>
                                <img src={logo} className='ml-2 h-auto max-w-[90%] lg:max-w-[80%]' />
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};