import lizcoinImage from '@/assets/images/heroes/7.webp';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { Button, buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';
import { VideoAnimation } from '@/components/animations/video';
import lizcoinVideo from '@/assets/animations/lizcoin.webm';
import { BrowserView, MobileView } from 'react-device-detect';

const LizcoinHero = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="lizcoin-hero"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 lg:min-h-[100vh]"
        >
            <div className='bg-eclipse absolute left-0 -top-[25%] h-[150%] w-[120%] rounded-full z-0' />
            <motion.div className='relative flex flex-col justify-center items-start gap-y-8 z-[1] pt-8 lg:pt-0' {...slideProps("left", isInView)}>
                <div className='flex flex-col gap-y-[18px]'>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[60px] !leading-9 lg:!leading-[64px] text-yellow'>Unlock Possibilities<br/> with Lizcoin</SectionTitle>
                    <SectionText isInView={true} className='text-foreground font-nekstbold text-xl lg:text-[32px] !leading-5 lg:!leading-10'>
                        Your Key to Web3 Gaming
                    </SectionText>
                </div>
                <BrowserView>
                    <Button disabled className={cn(buttonVariants({ variant: "outline" }), '!py-6')}>
                        Exchange listing coming soon...
                    </Button>
                </BrowserView>
            </motion.div>
            <div ref={ref} className='flex justify-center md:justify-end z-[1]'>
                <BrowserView renderWithFragment>
                    <VideoAnimation
                        isInView={isInView}
                        src={lizcoinVideo}
                        className='max-w-[325px] md:max-w-[425px] xl:max-w-[700px]'
                        {...slideProps("right",isInView)}
                    />
                </BrowserView>
                <MobileView className='flex flex-col w-full items-center'>
                    <motion.img
                        src={lizcoinImage}
                        className='max-w-[325px] md:max-w-[425px] xl:max-w-[700px]'
                        {...slideProps("right", isInView)}
                    />
                    <Button disabled className={cn(buttonVariants({ variant: "outline" }), '!py-6')}>
                        Exchange listing coming soon...
                    </Button>
                </MobileView>
            </div>
            <div className="absolute bottom-0 lg:h-32 bg-fade-in-purple w-full z-[1]" style={{ backgroundImage: 'linear-gradient(0deg, #000 0%, #000 60.5%, rgba(20, 20, 24, 0.00) 100%)', filter: 'blur(35px)'}} />
        </section>
    );
};

export default LizcoinHero;