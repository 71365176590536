
import { SectionText, SectionTitle } from '@/components/ui/text';

import twitterLizard from '@/assets/images/twitter-lizard.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { forwardRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import georgeVideo from '@/assets/animations/george.webm'
import { VideoAnimation } from '@/components/animations/video';
import { SectionProps } from '@/registry/home-sections';

export const FollowUs = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="fllowus" className={cn('flex h-full w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-center'>
                <div className='flex flex-col col-span-full gap-4 w-full items-center px-2 lg:px-0 text-left md:items-start'>
                    <div className='flex flex-col gap-4 justify-center'>
                        <SectionTitle isInView={isInView}>
                            Not sure about us? <br/>
                            Damn, that's <br/>
                            cold-blooded
                        </SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] md:max-w-lg lg:ext-base !leading-6'>
                            Our X account features award-winning shitposting and the most popular Web3 gaming spaces!
                        </SectionText>
                    </div>
                    <motion.a 
                        href="https://x.com/intent/user?screen_name=lizlabsio" 
                        target="_blank"
                        className={cn(buttonVariants(), 'font-bold px-6 py-7')}
                        {...slideProps("up", isInView, true)}
                    >
                        Follow us on X
                    </motion.a>
                </div>
            </div>
        </motion.section>
        <BrowserView renderWithFragment>
            {/* <motion.div 
                style={{ backgroundImage: `url(${twitterLizard})`, backgroundSize: 'auto 100%' }} 
                className='block absolute w-full bg-no-repeat h-full top-[8%] z-0 bg-center lg:bg-right-bottom'
                {...slideProps("right", isInView)}
            /> */}
          <VideoAnimation
              sectionIndex={7}
              isInView={isInView}
              videoId='george'
              className='block absolute bg-no-repeat h-full top-[8%] z-0 bg-center right-0'
              {...slideProps("right", isInView)}
          />
        </BrowserView>
        <MobileView renderWithFragment>
            <motion.img 
                src={twitterLizard} 
                className='fixed right-6 -bottom-6 h-auto w-5/6 z-0' 
                {...slideProps("right", isInView)}
            />
        </MobileView>
        </>
    );
});