import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import chestImage from '@/assets/images/heroes/lizcoin/chest.webp';
import securityImage from '@/assets/images/heroes/lizcoin/security.webp';
import safeImage from '@/assets/images/heroes/lizcoin/safe.webp';
import { cn } from '@/lib/utils';
import { isMobile } from 'react-device-detect';
import Stars from '@/components/stars';

interface BenefitType {
    image: string;
    imageClass: string;
    title: string;
    description: string | JSX.Element[] | JSX.Element;
}

const benefits: BenefitType[] = [
    {
        image: chestImage,
        imageClass: "-mt-8",
        title: "Lizard Labs Rewards",
        description: [
            <p>Engage in the ecosystem, get rewarded. Stake, complete bounties, play in tournaments and other gaming activities to earn Lizcoin, NFTs and other rewards.</p>,
            <p>Loyalty Revenue Rewards, funded by Lizard Labs revenue, begin 2 years after TGE.</p>
        ]
    },
    {
        image: securityImage,
        imageClass: "-mt-8",
        title: "Exclusive Access",
        description: [
            <p>Unleash the power of wagering in the best Web3 games, and Lizard League, with Lizcoin.</p>,
            <p>Access exclusive perks in the Lizard ecosystem, and our partners, that can only be purchased with Lizcoin, including tournament entries, special events, and in-game assets.</p>,
            <p>Shape the future of the Lizard Labs, using Lizcoin for governance of the Lizard Labs Gaming sub-DAO.</p>
        ]
    },
    {
        image: safeImage,
        imageClass: "-mb-8",
        title: "Partner Perks",
        description: [
            <p>Unlock access to blue-chip gaming tokens and other partner assets through our Play-4-Airdrop activations with top Web3 gaming studios.</p>,
            <p>Discover high-quality, curated, partner games and boost your potential rewards from our partnerships.</p>
        ]
    }
]

const BenefitCard = ({ benefit }: { benefit: BenefitType }) => {
    return isMobile ? (
        <div className='relative flex flex-col w-full items-center min-h-[300px] rounded-[20px]'>
            <div className='absolute top-40 left-0 right-0 bottom-0 bg-benefit-lizcoin-vertical opacity-80 rounded-[20px]' />
            <Stars className='absolute opacity-5 min-w-full h-dvh fill-yellow' />
            <motion.img src={benefit.image} className='relative w-[60%] max-h-[300px] mx-auto' />
            <div className='flex flex-col items-start text-left gap-y-6 p-6'>
                <h5 className='font-nekstbold text-[24px] lg:!text-[36px] !leading-7 lg:!leading-10 text-yellow'>{benefit.title}</h5>
                <div className='flex flex-col text-white gap-y-5 !font-figtree text-base'>
                    {benefit.description}
                </div>
            </div>
        </div>
    ) : (
        <div className='relative flex w-full items-center min-h-[300px] rounded-[20px]'>
            <div className='absolute top-0 left-32 right-0 bottom-0 bg-benefit-lizcoin opacity-80 rounded-[20px]' />
            <div className='absolute flex h-full items-center'>
                <motion.img src={benefit.image} className={cn('relative max-w-[300px] max-h-[300px]', benefit.imageClass ? benefit.imageClass : '')}/>
            </div>
            <div className='flex flex-col items-start text-left gap-y-6 pl-[calc(150px_+_14rem)]'>
                <h5 className='font-nekstbold text-[24px] lg:!text-[36px] !leading-7 lg:!leading-10 text-yellow'>{benefit.title}</h5>
                <div className='flex flex-col text-white gap-y-5 !font-figtree text-base'>
                    {benefit.description}
                </div>
            </div>
        </div>
    )
}

const LizcoinBenefits = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="lizcoin-benefits"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 lg:py-20 px-6 md:px-20 w-full"
        >
            <div ref={ref} className='relative flex flex-col gap-y-8 md:gap-x-8 items-center justify-center w-full lg:py-20'>
                {benefits.map((bnft, x) => (
                    <motion.div 
                        key={`lizcoin_benefit_${x}`} 
                        className="flex flex-col text-center gap-y-6 rounded-[10px] overflow-hidden w-full max-w-6xl"
                        transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
                        animate={isInView ? "show" : "hide"}
                        variants={{
                            show: {
                                opacity: 1,
                                y: 0
                            },
                            hide: {
                                opacity: 0,
                                y: -80
                            }
                        }}
                    >
                        <BenefitCard key={`lizcoint_benefit_card_${x}`} benefit={bnft} />
                    </motion.div>
                ))}
            </div>
        </section>
    );
};

export default LizcoinBenefits;