import { SectionText, SectionTitle } from '@/components/ui/text';
import shinyCoinImage from '@/assets/images/heroes/lizcoin/shiny-coins.webp';
import nftsImage from '@/assets/images/heroes/lizcoin/nfts.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

export const ShinyLizcoin = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="shiny-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:justify-between md:flex-row gap-y-8 pt-20 px-6 md:px-20 min-h-[100vh]"
        >
            <div ref={ref} className='flex justify-start mx-auto w-full z-[1] h-full'>
                
                <motion.div className='relative flex flex-col gap-y-[18px] col-span-1 max-w-[600px] justify-start items-start' {...slideProps("up", isInView)}>
                    <SectionTitle isInView={true} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>
                        How can I get even more shiny Lizcoins?
                    </SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Guardians of Gaming™ are rewarded for active participation via simple bounties in our Lizard Lounge web app. Support partner Web3 games and forge stronger community bonds to earn even more!
                        </SectionText>
                        <SectionText isInView={true} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Ethlizards Genesis and Venture NFTs are the only way to secure guaranteed Lizcoin airdrops. Learn more about the <Link className='text-yellow underline' to="/ethlizards">Ethlizards NFTs</Link>
                        </SectionText>
                        <div className='w-full flex justify-end lg:justify-normal'>
                            <motion.img
                                src={nftsImage}
                                className='lg:ml-[10%] -mt-14 max-w-[300px] -mr-12 lg:mr-0'
                                {...slideProps("left", isInView)}
                            />
                        </div>
                        <MobileView renderWithFragment>
                            <motion.img
                                src={shinyCoinImage}
                                className='w-full -mt-20'
                                {...slideProps("right", isInView)}
                            />
                        </MobileView>
                    </div>
                </motion.div>
                <BrowserView>
                    <motion.img
                        src={shinyCoinImage}
                        className='absolute lg:-right-32 xl:-right-44 top-0 h-full'
                        {...slideProps("right", isInView)}
                    />
                </BrowserView>
            </div>
        </section>
    );
};