import { animations } from "@/components/animations/list";
import { slideProps } from "@/components/animations/utils";
import { useSections } from "@/components/section-provider";
import { useDimensions } from "@/hooks/use-dimensions";
import { HTMLMotionProps, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

interface VideoAnimationType extends HTMLMotionProps<"video"> {
    isInView: boolean;
    className: string;
    src?: string;
    sectionIndex?: number;
    videoId?: string;
}

export const VideoAnimation = ({
    isInView,
    sectionIndex,
    videoId,
    src,
    ...props
}: VideoAnimationType) => {

    const { section } = useSections();
    const { width } = useDimensions();

    const videoRef = useRef<HTMLVideoElement>(null);

    const [loaded, setLoaded] = useState(false);

    const loadVideo = isInView || ((section-1 === sectionIndex) || (section+1 === sectionIndex));

    const animation = animations.find(a => a.id === videoId);
    const videoSrc = src ? src : animation?.resolutions.find(r => r.bounds[0] < width && r.bounds[1] > width)?.source;

    useEffect(() => {
        let timeout: undefined | NodeJS.Timeout;
        if (videoRef?.current && loaded) {
            try {
                if (isInView) {
                    timeout = setTimeout(() => {
                        videoRef?.current?.play();
                    }, 0);
                } else {
                    videoRef?.current?.pause();
                }
            } catch(e) {
                console.log(e);
            }
        }

        return () => {
            clearTimeout(timeout);
            if (loaded) {
                videoRef?.current?.pause?.();
            }
        }
    }, [videoRef, isInView, loaded])

    return (!loadVideo && !loaded) || !videoSrc ? null : (
        <motion.video 
            ref={videoRef}
            poster={undefined}
            autoPlay={false}
            loop
            muted
            playsInline
            onLoadedData={() => {
                setLoaded(true);
            }}
            {...slideProps("right", isInView)}
            {...props}
        >
            <source src={videoSrc} type="video/webm" />
        </motion.video>
    );
};