import { Routes, Route } from "react-router-dom";
import { ThemeWrapper } from "@/components/theme-wrapper";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import logo from '@/assets/images/logo.png';
import { homeSections } from "@/registry/home-sections";
import { HomePage } from "@/pages/home";
import EthlizardsPage from "@/pages/ethlizards";
import LizcoinPage from "@/pages/lizcoin";
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.getElementById(homeSections[0].id)?.scrollIntoView();
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [])

  return (
    <ThemeWrapper>
      <AnimatePresence>
        {loading && (
          <motion.div 
            className='fixed inset-0 z-[100] h-screen w-full bg-background flex justify-center items-center'
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={logo} className='max-w-xs bounce' />
          </motion.div>
        )}
      </AnimatePresence>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ethlizards" element={<EthlizardsPage />} />
        <Route path="/lizcoin" element={<LizcoinPage />} />
      </Routes>
    </ThemeWrapper>
  );
}

export default App;
